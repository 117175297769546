import React, { useRef } from 'react';

import CarouselControls from './CarouselControls';
import Portal from './Popovers/Portal';

type Props = {
  children: JSX.Element[];
  controlsClassName?: string;
  controlsDestinationId: string;
  listClassName?: string;
  onPageChange?: (index: number) => void;
  onPageIndexSet?: (index: number) => void;
};

export default (function Carousel({
  children,
  controlsClassName,
  controlsDestinationId,
  listClassName,
  onPageIndexSet,
}) {
  const listElementRef = useRef<HTMLOListElement>(null);
  const listClasses = ['carousel', listClassName].filter(Boolean);

  let carouselControlsPortal: JSX.Element | null = null;
  if (listElementRef.current) {
    carouselControlsPortal =
      <Portal id={controlsDestinationId}>
        <CarouselControls
          className={controlsClassName}
          listElement={listElementRef.current}
          onPageIndexSet={onPageIndexSet}
        />
      </Portal>;
  }

  return (
    <>
      {carouselControlsPortal}

      <ol className={listClasses.join(' ')} ref={listElementRef}>
        {children}
        <li aria-hidden key="spacer" />
      </ol>
    </>
  );
} as React.FC<Props>);
