import React from 'react';

import Icon from './Icon';

export default function CheckmarkInCircleIcon({
  className,
  text = '',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_12077_114217)">
          <rect width="16" height="16" rx="8" fill="currentColor" />
          <path
            d="M12.2071 4.79289C12.5976 5.18342 12.5976 5.81658 12.2071 6.20711L7.20711 11.2071C6.81658 11.5976 6.18342 11.5976 5.79289 11.2071L3.79289 9.20711C3.40237 8.81658 3.40237 8.18342 3.79289 7.79289C4.18342 7.40237 4.81658 7.40237 5.20711 7.79289L6.5 9.08579L10.7929 4.79289C11.1834 4.40237 11.8166 4.40237 12.2071 4.79289Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_12077_114217">
            <rect width="16" height="16" rx="8" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
