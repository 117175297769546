import React from 'react';

import Icon from './Icon';

export default function ViewsIcon({
  className = '',
  text = 'Views',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8905 0.582334C16.2796 0.339092 16.7734 0.339092 17.1626 0.582334L29.9626 8.58317C30.3134 8.80248 30.5265 9.18705 30.5265 9.6008C30.5265 10.0145 30.3133 10.3991 29.9625 10.6184L17.1625 18.6175C16.7734 18.8607 16.2797 18.8607 15.8906 18.6175L3.09058 10.6184C2.73971 10.3991 2.52655 10.0145 2.52653 9.6008C2.52651 9.18705 2.73963 8.80248 3.09048 8.58317L15.8905 0.582334ZM5.99068 9.60063L16.5265 16.1848L27.0624 9.60063L16.5265 3.01504L5.99068 9.60063Z"
          fill="currentColor"
        />
        <path
          d="M2.70893 15.3639C3.06018 14.8019 3.80052 14.6311 4.36253 14.9823L16.5265 22.5848L28.6905 14.9823C29.2525 14.6311 29.9929 14.8019 30.3441 15.3639C30.6954 15.9259 30.5245 16.6662 29.9625 17.0175L17.1625 25.0175C16.7734 25.2607 16.2797 25.2607 15.8905 25.0175L3.09053 17.0175C2.52853 16.6662 2.35768 15.9259 2.70893 15.3639Z"
          fill="currentColor"
        />
        <path
          d="M2.70893 21.7639C3.06018 21.2019 3.80052 21.0311 4.36253 21.3823L16.5265 28.9848L28.6905 21.3823C29.2525 21.0311 29.9929 21.2019 30.3441 21.7639C30.6954 22.3259 30.5245 23.0662 29.9625 23.4175L17.1625 31.4175C16.7734 31.6607 16.2797 31.6607 15.8905 31.4175L3.09053 23.4175C2.52853 23.0662 2.35768 22.3259 2.70893 21.7639Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
