import { FilteredView } from '../../types';
import reorder from '../../utils/reorder';
import { globalState, updateState } from '../models';

type CommonActionOptions = { userInteraction: string | null; };

export async function addViewToHome(
  {
    id,
    index,
  }: {
    id: FilteredView['id'];
    index: number | 'append';
  },
  { userInteraction }: CommonActionOptions,
) {
  if (globalState.getState().persistent.home?.views?.includes(id)) {
    return;
  }
  await updateState(
    (state) => {
      if (!state.persistent.home) {
        state.persistent.home = { views: [] };
      }
      if (index === 'append') {
        state.persistent.home.views.push(id);
        return;
      }
      state.persistent.home.views.splice(index, 0, id);
    },
    { eventName: 'view-added-to-home', userInteraction },
  );
}

export async function reorderViewInHome(
  {
    id,
    newIndex,
  }: {
    id: FilteredView['id'];
    newIndex: number;
  },
  { userInteraction }: CommonActionOptions,
) {
  const viewsInState = globalState.getState().persistent.home?.views;
  if (!viewsInState) {
    return;
  }

  const oldIndex = viewsInState.indexOf(id);
  if (oldIndex === -1) {
    throw new Error("That view isn't in home");
  }

  await updateState(
    (state) => {
      if (!state.persistent.home?.views) {
        // Just for TypeScript
        throw new Error('No views in state to update');
      }
      state.persistent.home.views = reorder(state.persistent.home.views, oldIndex, newIndex);
    },
    { eventName: 'view-removed-from-home', userInteraction },
  );
}

export async function removeViewFromHome({
  id,
  userInteraction,
}: { id: FilteredView['id']; } & CommonActionOptions) {
  const viewsInState = globalState.getState().persistent.home?.views;
  if (!viewsInState || !viewsInState.includes(id)) {
    return;
  }
  await updateState(
    (state) => {
      if (!state.persistent.home?.views) {
        // Just for TypeScript
        throw new Error('No views in state to update');
      }
      const index = state.persistent.home.views.indexOf(id);
      if (index === -1) {
        throw new Error("Can't find that ID in home views");
      }
      state.persistent.home.views.splice(index, 1);
    },
    { eventName: 'view-removed-from-home', userInteraction },
  );
}
